import {
  createMuiTheme
} from "@material-ui/core"

const Theme = createMuiTheme({
  typography: {
    fontFamily: [
      "Avenir"
    ],
  },
  palette: {
    background: {
      // default: "#f7fcfe"
      default: "#F3F2EF"
    },
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#673AB7',
    },
    text: {
      // primary: "#5B5B5B"
    }
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: '50px',
        textTransform: 'default'
      }
    }
  }
});

export default Theme
